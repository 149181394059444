import { Route, Switch } from 'react-router-dom';
import usePageViews from 'hooks/usePageViews';
import TimerTest from 'pages/development/component/Timer';
import PrivateRoute from 'PrivateRoute';
import FeatureRoute from 'FeatureRoute';
import TestRoute from 'TestRoute';
import { retryLazy } from 'utils/lazyUtil';

const AddressList = retryLazy(() => import('pages/AddressList'));
const AddressEdit = retryLazy(() => import('pages/AddressEdit'));
const AppSetting = retryLazy(() => import('pages/AppSetting'));
const ArticleDetail = retryLazy(() => import('pages/ArticleDetail'));
const Board = retryLazy(() => import('pages/Board'));
const BoardDetail = retryLazy(() => import('pages/BoardDetail'));
const BoardEdit = retryLazy(() => import('pages/BoardEdit'));
const BookingOpenSchedules = retryLazy(
  () => import('pages/BookingOpenSchedules'),
);
const CampAnnounceDetail = retryLazy(() => import('pages/CampAnnounceDetail'));
const CampAnnounceList = retryLazy(() => import('pages/CampAnnounceList'));
const CampDetail = retryLazy(() => import('pages/CampDetail'));
const CamperInfo = retryLazy(() => import('pages/CamperInfo'));
const CampLogList = retryLazy(() => import('pages/CampLogList'));
const CampingLogDetail = retryLazy(() => import('pages/CampingLogDetail'));
const Comment = retryLazy(() => import('pages/CommentDetail'));
const CommentList = retryLazy(() => import('pages/Comments'));
const CompleteRegisteringRealUser = retryLazy(
  () => import('pages/CompleteRegisteringRealUser'),
);
const CouponDetail = retryLazy(() => import('pages/CouponDetail'));
const CampExhibition = retryLazy(() => import('pages/CampExhibition'));
const Exhibition = retryLazy(() => import('pages/Exhibition'));
const IdFind = retryLazy(() => import('pages/IdFind'));
const ItemsTimeline = retryLazy(() => import('pages/ItemsTimeline'));
const Landing = retryLazy(() => import('pages/Landing'));
const LogEdit = retryLazy(() => import('pages/LogEdit'));
const Login = retryLazy(() => import('pages/LogIn'));
const LoginWithId = retryLazy(() => import('pages/LoginWithId'));
const LoginProgress = retryLazy(() => import('pages/LoginProgress'));
const Main = retryLazy(() => import('pages/Main'));
const MyAlarms = retryLazy(() => import('pages/MyAlarms'));
const MyBlockedHistory = retryLazy(() => import('pages/MyBlockedHistory'));
const MyBookmarks = retryLazy(() => import('pages/MyBookmarks'));
const MyCampingLog = retryLazy(() => import('pages/MyCampingLog'));
const MyCoupons = retryLazy(() => import('pages/MyCoupons'));
const MyPageCouponRegister = retryLazy(
  () => import('pages/MyPageCouponRegister'),
);
const MyInfoEdit = retryLazy(() => import('pages/MyInfoEditPage'));
const MyItemEdit = retryLazy(() => import('pages/MyItemEdit'));
const MyList = retryLazy(() => import('pages/MyList'));
const MyPage = retryLazy(() => import('pages/MyPage'));
const MyPageEvent = retryLazy(() => import('pages/MyPageEvent'));
const Shopping = retryLazy(() => import('pages/Shopping'));
const MyPageExhibition = retryLazy(() => import('pages/MyPageExhibition'));
const MyReservationList = retryLazy(() => import('pages/MyReservationList'));
const MyVacancyAlarms = retryLazy(() => import('pages/MyVacancyAlarms'));
const NicknameSetting = retryLazy(() => import('pages/UserNicknameSetting'));
const NonReservationList = retryLazy(() => import('pages/NonReservationList'));
const NonReservationResult = retryLazy(
  () => import('pages/NonReservationResult'),
);
const Notice = retryLazy(() => import('pages/Notice'));
const NoticeDetail = retryLazy(() => import('pages/NoticeDetail'));
const Pamphlet = retryLazy(() => import('pages/Pamphlet'));
const PasswordFind = retryLazy(() => import('pages/PasswordFind'));
const PasswordSetting = retryLazy(() => import('pages/UserPasswordSetting'));
const Payment = retryLazy(() => import('pages/Payment'));
const PaymentLoading = retryLazy(() => import('pages/PaymentLoading'));
const PaymentFail = retryLazy(() => import('pages/PaymentFail'));
const ProductDetail = retryLazy(() => import('pages/NewProductDetail'));
const ProductExhibition = retryLazy(() => import('pages/ProductExhibition'));
const ProductMain = retryLazy(() => import('pages/ProductMain'));
const ProductMainCategory = retryLazy(
  () => import('pages/ProductMainCategory'),
);
const ProductQnaList = retryLazy(() => import('pages/ProductQnaList'));
const ProductQuestionEdit = retryLazy(
  () => import('pages/ProductQuestionEdit'),
);
const ProductReviewDetail = retryLazy(
  () => import('pages/ProductReviewDetail'),
);
const ProductReviewList = retryLazy(() => import('pages/ProductReviewList'));
// Search 검색
const ProductSearchResults = retryLazy(
  () => import('pages/ProductSearchResults'),
);
const RelatedProductList = retryLazy(() => import('pages/RelatedProductList'));
const RealtimeReservation = retryLazy(
  () => import('pages/Search/RealtimeReservation'),
);
const SearchHome = retryLazy(() => import('pages/Search/SearchHome'));
const CollectionDetail = retryLazy(() => import('pages/Collection'));
const Reservation = retryLazy(() => import('pages/Reservation'));
const ReservationAccountInfo = retryLazy(
  () => import('pages/ReservationAccountInfo'),
);
const ReservationRefund = retryLazy(() => import('pages/ReservationRefund'));
const ReservationResult = retryLazy(() => import('pages/ReservationResult'));
const ReservationServiceInventory = retryLazy(
  () => import('pages/ReservationServiceInventory'),
);
const SearchList = retryLazy(() => import('pages/SearchResult/SearchResults'));
const NewSearchResultList = retryLazy(
  () => import('pages/SearchResult/NewSearchResultList'),
);
const SearchVacancy = retryLazy(() => import('pages/SearchVacancy'));
const SearchVacancySite = retryLazy(() => import('pages/SearchVacancySite'));
const SearchGeo = retryLazy(() => import('pages/SearchGeo'));
const Settings = retryLazy(() => import('pages/Settings'));
const SignUp = retryLazy(() => import('pages/SignUp'));
const SignUpComplete = retryLazy(() => import('pages/SignUpComplete'));
const SignUpTerms = retryLazy(() => import('pages/SignUp/SocialSignUpTerms'));
const SignUpPhoneAuth = retryLazy(
  () => import('pages/SignUp/SocialSignUpPhoneAuth'),
);
const SignUpForm = retryLazy(() => import('pages/SignUp/SocialSignUpForm'));
const SignUpConnect = retryLazy(
  () => import('pages/SignUp/SocialSignUpConnect'),
);
const SocialLoginGuide = retryLazy(() => import('pages/SocialLoginGuide'));
const SubBannerDetail = retryLazy(() => import('pages/SubBannerDetail'));
const TermsDetail = retryLazy(() => import('pages/TermsDetail'));
const TooManyReqError = retryLazy(() => import('pages/TooManyReqError'));
const UserBriefSetting = retryLazy(() => import('pages/UserBriefSetting'));
const UserSnsSetting = retryLazy(() => import('pages/UserSnsSetting'));
const UserCarNumberSetting = retryLazy(
  () => import('pages/UserCarNumberSetting'),
);
const UserPetsSetting = retryLazy(() => import('pages/UserPetsSetting'));
const UserPetsEdit = retryLazy(() => import('pages/UserPetsEdit'));
const UserPhoneSetting = retryLazy(() => import('pages/UserPhoneSetting'));
const Withdrawal = retryLazy(() => import('pages/Withdrawal'));
const ZoneDetail = retryLazy(() => import('pages/ZoneDetail'));
const SiteReservationStatus = retryLazy(
  () => import('pages/ZoneReservationStatus'),
);
const HostsAndSuppliersList = retryLazy(
  () => import('pages/HostsAndSuppliersList'),
);

/* store */
const ShopbyItemDetail = retryLazy(() => import('pages/ShopbyItemDetail'));
const ShopbyPhotoReviewList = retryLazy(
  () => import('pages/ShopbyPhotoReviewList'),
);
const ShopbyPhotoReviewDetail = retryLazy(
  () => import('pages/ShopbyPhotoReviewDetail'),
);
const ShopbyReviewListDetail = retryLazy(
  () => import('pages/ShopbyReviewListDetail'),
);
const OrderSheet = retryLazy(() => import('pages/OrderSheet'));
const OrderResult = retryLazy(() => import('pages/OrderResult'));
const DormantAccount = retryLazy(() => import('pages/DormantAccount'));
const ShopbyItemInquiry = retryLazy(() => import('pages/ShopbyItemInquiry'));
const ShopbyItemInquirySuccess = retryLazy(
  () => import('pages/ShopbyItemInquirySuccess'),
);
const ShopbyAllExhibition = retryLazy(
  () => import('pages/ShopbyAllExhibition'),
);
const ShopbyBestReview = retryLazy(() => import('pages/ShopbyBestReview'));
const ShopbyCuration = retryLazy(() => import('pages/ShopbyCuration'));

/* store - my */
const MyOrderList = retryLazy(() => import('pages/MyOrderList'));
const MyOrder = retryLazy(() => import('pages/MyOrder'));
const MyOrderClaim = retryLazy(() => import('pages/MyOrderClaim'));
const MyClaimComplete = retryLazy(() => import('pages/MyClaimComplete'));
const MyClaimDetail = retryLazy(() => import('pages/MyClaimDetail'));
const MyInquiry = retryLazy(() => import('pages/MyInquiry'));
const MyItemReviewList = retryLazy(() => import('pages/MyItemReviewList'));
const MyItemReviewWriteAndEdit = retryLazy(
  () => import('pages/MyItemReviewWriteAndEdit'),
);
const MyItemReviewSuccess = retryLazy(
  () => import('pages/ShopbyMyItemReviewSuccess'),
);
const MyItemReviewDtail = retryLazy(() => import('pages/MyItemReviewDetail'));
const MyPoint = retryLazy(() => import('pages/MyPoint'));
const StoreCoupon = retryLazy(() => import('pages/StoreCoupon'));

const ProductExhibitionList = retryLazy(
  () => import('pages/ProductExhibitionList'),
);

const MyProductReview = retryLazy(() => import('pages/MyProductReview'));

/** 캠핑생활 */
const HomeFeed = retryLazy(() => import('pages/Feed/HomeFeed'));
const FeedWrite = retryLazy(() => import('pages/Feed/FeedWrite'));
const FeedDetail = retryLazy(() => import('pages/Feed/FeedDetail'));

/* development */

const development = retryLazy(() => import('pages/development/index'));

const Inputs = retryLazy(() => import('pages/development/component/Inputs'));

const BasicButtonTest = retryLazy(
  () => import('pages/development/component/BasicButtons'),
);
const NewBasicButtonTest = retryLazy(
  () => import('pages/development/component/NewBasicButtons'),
);
const IconComponentTest = retryLazy(
  () => import('pages/development/component/IconComponents'),
);
const ModalTest = retryLazy(() => import('pages/development/component/Modals'));
const ReactDayPickerTestComponent = retryLazy(
  () => import('pages/development/component/ReactDayPickerTestComponent'),
);
const Typographys = retryLazy(
  () => import('pages/development/component/Typographys'),
);
const Toast = retryLazy(() => import('pages/development/component/Toast'));
const AutenticationTest = retryLazy(
  () => import('pages/development/component/SelfAuthentication'),
);
const CouponTest = retryLazy(
  () => import('pages/development/component/Coupons'),
);
const CartPage = retryLazy(() => import('pages/development/component/Cart'));
const VirtualizedTestPage = retryLazy(
  () => import('pages/development/dev/Virtualized'),
);

const NotionDBTestPage = retryLazy(
  () => import('pages/development/dev/NotionDBTest'),
);

/* Laboratory */
const LaboratoryIndex = retryLazy(() => import('pages/Laboratory'));
const NotionTermsTestPage = retryLazy(
  () => import('pages/Laboratory/NotionTermsTest'),
);
const NotionStoreCategoryTestPage = retryLazy(
  () => import('pages/Laboratory/NotionStoreCategory'),
);
const NotionDailyStatisticsTestPage = retryLazy(
  () => import('pages/Laboratory/NotionDailyStatistics'),
);

const ShopbyBestReviewTest = retryLazy(
  () => import('pages/Laboratory/Store/ShopbyBestReviewTestPage'),
);
const SiteExhibition = retryLazy(() => import('pages/SiteExhibition'));

const SwitchRoute = () => {
  usePageViews();

  return (
    <Switch>
      <Route exact path="/" component={Main} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/login/id" component={LoginWithId} />
      <Route
        exact
        path="/login/progress/:socialLogin"
        component={LoginProgress}
      />
      <Route exact path="/login/guide" component={SocialLoginGuide} />
      <Route exact path="/sign-up" component={SignUp} />
      <Route exact path="/sign-up/complete" component={SignUpComplete} />
      <Route exact path="/sign-up/terms" component={SignUpTerms} />
      <Route exact path="/sign-up/phone-auth" component={SignUpPhoneAuth} />
      <Route exact path="/sign-up/form" component={SignUpForm} />
      <Route exact path="/sign-up/connect" component={SignUpConnect} />
      <Route exact path="/find/id" component={IdFind} />
      <Route exact path="/find/pw" component={PasswordFind} />
      <Route exact path="/camp/exhibition" component={CampExhibition} />
      <Route exact path="/camp/:campId" component={CampDetail} />
      <Route
        exact
        path="/camp/:campId/announces"
        component={CampAnnounceList}
      />
      <Route
        exact
        path="/camp/:campId/announces/:announceId"
        component={CampAnnounceDetail}
      />
      <Route exact path="/camp/:campId/log" component={CampLogList} />
      <Route exact path="/camp/:campId/:zoneId/log" component={CampLogList} />
      <Route exact path="/camp/:campId/:zoneId" component={ZoneDetail} />
      <Route
        exact
        path="/zones/:zoneId/calendar"
        component={SiteReservationStatus}
      />
      <Route exact path="/camp/:campId/log/:id" component={CampingLogDetail} />
      <Route
        exact
        path="/camp/:campId/:zoneId/log/:id"
        component={CampingLogDetail}
      />
      <FeatureRoute
        exact
        path="/search"
        featureFlagsKey="new-search"
        featureComponent={SearchHome}
        oldComponent={RealtimeReservation}
      />
      <FeatureRoute
        exact
        path="/search/result"
        featureFlagsKey="new-search"
        featureComponent={NewSearchResultList}
        oldComponent={SearchList}
      />
      <Route exact path="/search/vacancy" component={SearchVacancy} />
      <Route exact path="/search/vacancy-site" component={SearchVacancySite} />
      <Route exact path="/search/geo" component={SearchGeo} />
      <Route exact path="/reservation" component={Reservation} />
      <Route exact path="/reservation/payment" component={Payment} />
      <Route
        exact
        path="/reservation/result/:id"
        component={ReservationResult}
      />
      <Route
        exact
        path="/reservation/inventory"
        component={ReservationServiceInventory}
      />
      <Route exact path="/reservation/refund" component={ReservationRefund} />
      <Route
        exact
        path="/reservation/refund/account-info"
        component={ReservationAccountInfo}
      />
      <Route exact path="/log/:id" component={CampingLogDetail} />
      <Route
        exact
        path="/reservation/payment/loading"
        component={PaymentLoading}
      />
      <Route exact path="/reservation/payment/fail" component={PaymentFail} />
      {/* 캠핑생활 시작 */}
      <Route exact path="/feed" component={HomeFeed} />
      <Route exact path="/feed/write" component={FeedWrite} />
      <Route exact path="/feed/:id" component={FeedDetail} />
      <Route
        exact
        path="/feed/:id/comments/:commentid"
        component={FeedDetail}
      />
      {/* 마이페이지 시작 */}
      <Route exact path="/mypage" component={MyPage} />
      <Route
        exact
        path="/mypage/non/reservation"
        component={NonReservationList}
      />
      <Route
        exact
        path="/mypage/non/reservation/list"
        component={NonReservationResult}
      />
      <PrivateRoute
        exact
        path="/mypage/reservation"
        component={MyReservationList}
      />
      <PrivateRoute
        exact
        path="/mypage/reservation/edit"
        component={MyCampingLog}
      />
      <PrivateRoute exact path="/mypage/mylist" component={MyList} />
      <PrivateRoute exact path="/mypage/log/edit" component={LogEdit} />
      <PrivateRoute exact path="/mypage/log/edit/:id" component={LogEdit} />
      <Route exact path="/mypage/notice" component={Notice} />
      <Route exact path="/mypage/notice/:id" component={NoticeDetail} />
      <PrivateRoute exact path="/mypage/coupon" component={MyCoupons} />
      <PrivateRoute
        exact
        path="/mypage/coupon/register"
        component={MyPageCouponRegister}
      />
      <PrivateRoute exact path="/mypage/coupon/:id" component={CouponDetail} />
      <PrivateRoute exact path="/mypage/bookmark" component={MyBookmarks} />
      <Route exact path="/mypage/event" component={MyPageEvent} />
      <Route exact path="/shopping" component={Shopping} />
      <Route exact path="/mypage/exhibition" component={MyPageExhibition} />
      <Route exact path="/store/exhibition" component={ShopbyAllExhibition} />
      <PrivateRoute
        exact
        path="/mypage/items/review"
        component={MyProductReview}
      />
      <Route exact path="/store/bestReview" component={ShopbyBestReview} />
      <Route exact path="/curation" component={ShopbyCuration} />
      {/* 마이 - 스토어 시작 */}
      <PrivateRoute exact path="/mypage/store/orders" component={MyOrderList} />
      <PrivateRoute
        exact
        path="/mypage/store/orders/:orderNo"
        component={MyOrder}
      />
      <PrivateRoute
        exact
        path="/mypage/store/claim/complete"
        component={MyClaimComplete}
      />
      <PrivateRoute
        exact
        path="/mypage/store/orders/claim/detail"
        component={MyClaimDetail}
      />
      <PrivateRoute
        exact
        path="/mypage/store/orders/claim/:orderOptionNo"
        component={MyOrderClaim}
      />
      <PrivateRoute exact path="/mypage/store/inquiry" component={MyInquiry} />
      <PrivateRoute
        exact
        path="/mypage/store/reviews"
        component={MyItemReviewList}
      />
      <PrivateRoute
        exact
        path="/mypage/store/review/:productNo"
        component={MyItemReviewWriteAndEdit}
      />
      <PrivateRoute
        exact
        path="/mypage/store/review/edit/success/:productNo/:reviewNo"
        component={MyItemReviewSuccess}
      />
      <PrivateRoute
        exact
        path="/mypage/store/review/detail/:productNo/:reviewNo"
        component={MyItemReviewDtail}
      />
      <PrivateRoute exact path="/mypage/store/coupon" component={StoreCoupon} />
      {/* 마이 - 스토어 끝 */}
      {/* 마이페이지 끝 */}
      <Route exact path="/exhibition/:id" component={Exhibition} />
      <Route exact path="/pamphlet" component={Pamphlet} />
      <Route exact path="/contents" component={SubBannerDetail} />
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/error" component={TooManyReqError} />
      <PrivateRoute exact path="/mypage/info/edit" component={MyInfoEdit} />
      <PrivateRoute
        exact
        path="/settings/password"
        component={PasswordSetting}
      />
      <PrivateRoute
        exact
        path="/settings/nickname"
        component={NicknameSetting}
      />
      <PrivateRoute exact path="/settings/brief" component={UserBriefSetting} />
      <PrivateRoute exact path="/settings/sns" component={UserSnsSetting} />
      <PrivateRoute
        exact
        path="/settings/carnumber"
        component={UserCarNumberSetting}
      />
      <PrivateRoute exact path="/settings/pets" component={UserPetsSetting} />
      <PrivateRoute exact path="/settings/pets/edit" component={UserPetsEdit} />
      <PrivateRoute exact path="/settings/phone" component={UserPhoneSetting} />
      <PrivateRoute exact path="/withdrawal" component={Withdrawal} />
      <PrivateRoute exact path="/board/edit" component={BoardEdit} />
      <PrivateRoute exact path="/board/edit/:id" component={BoardEdit} />
      <Route exact path="/board" component={Board} />
      <Route exact path="/board/:boardId" component={BoardDetail} />
      <Route
        exact
        path="/board/:boardId/comments/:commentId"
        component={Comment}
      />
      <Route exact path="/board/:boardId/comments" component={CommentList} />
      <Route exact path="/terms/:id" component={TermsDetail} />
      {/* <Route exact path="/landing/:id" component={Landing} /> */}
      <Route
        exact
        path="/landing/:id"
        render={({ match }) => <Landing key={match.params.id} />}
      />
      <Route exact path="/mypage/alarms" component={MyAlarms} />
      <Route exact path="/users/:nickname" component={CamperInfo} />
      <Route exact path="/schedules" component={BookingOpenSchedules} />
      <PrivateRoute
        exact
        path="/mypage/vacancy-alarms"
        component={MyVacancyAlarms}
      />
      <PrivateRoute exact path="/settings/block" component={MyBlockedHistory} />
      <Route exact path="/board/articles/:id" component={ArticleDetail} />
      <Route exact path="/app" component={AppSetting} />
      <PrivateRoute exact path="/mypage/item/edit" component={MyItemEdit} />
      <Route
        exact
        path="/complete/registering/real-user"
        component={CompleteRegisteringRealUser}
      />
      <Route exact path="/items/:nickname" component={ItemsTimeline} />
      <Route exact path="/addressList" component={AddressList} />
      <Route exact path="/addressEdit" component={AddressEdit} />
      <Route exact path="/products" component={ProductSearchResults} />
      <Route exact path="/products/:id" component={ProductDetail} />
      <PrivateRoute
        exact
        path="/products/:id/question/edit"
        component={ProductQuestionEdit}
      />
      <Route exact path="/product" component={ProductMain} />
      <Route exact path="/product/questions" component={ProductQnaList} />
      <Route exact path="/product/reviews" component={ProductReviewList} />
      <Route
        exact
        path="/product/related/:productId"
        component={RelatedProductList}
      />
      <Route
        exact
        path="/product/:categoryId"
        component={ProductMainCategory}
      />
      <Route
        exact
        path="/product-exhibition"
        component={ProductExhibitionList}
      />
      <Route
        exact
        path="/product-exhibition/:id"
        component={ProductExhibition}
      />
      <Route
        exact
        path="/product/reviews/:id"
        component={ProductReviewDetail}
      />
      <PrivateRoute
        exact
        path="/store/ordersheet/:orderSheetNo"
        component={OrderSheet}
      />
      <PrivateRoute exact path="/store/orderResult" component={OrderResult} />
      <Route
        exact
        path="/products/items/:shopbyProductNo"
        component={ShopbyItemDetail}
      />{' '}
      <Route
        exact
        path="/store/items/photoReview/:shopbyProductNo"
        component={ShopbyPhotoReviewList}
      />
      <Route
        exact
        path="/store/items/photoReview/detail/:shopbyProductNo/:reviewNo"
        component={ShopbyPhotoReviewDetail}
      />
      <Route
        exact
        path="/store/items/reviewDetail/:shopbyProductNo"
        component={ShopbyReviewListDetail}
      />
      <Route exact path="/collection/:id" component={CollectionDetail} />
      <PrivateRoute
        exact
        path="/store/inquiry/:productNo"
        component={ShopbyItemInquiry}
      />
      <PrivateRoute
        exact
        path="/store/inquiry/:productNo/success"
        component={ShopbyItemInquirySuccess}
      />
      <PrivateRoute exact path="/mypage/store/point" component={MyPoint} />
      <Route exact path="/hosts" component={HostsAndSuppliersList} />
      <Route exact path="/suppliers" component={HostsAndSuppliersList} />
      <Route exact path="/dormant" component={DormantAccount} />
      <TestRoute exact path="/development" component={development} />
      <TestRoute
        exact
        path="/development/component/BasicButton"
        component={BasicButtonTest}
      />
      <TestRoute
        exact
        path="/development/component/NewBasicButton"
        component={NewBasicButtonTest}
      />
      <TestRoute
        exact
        path="/development/component/Icon"
        component={IconComponentTest}
      />
      <TestRoute
        exact
        path="/development/component/Modal"
        component={ModalTest}
      />
      <TestRoute
        exact
        path="/development/component/Timer"
        component={TimerTest}
      />
      <TestRoute
        exact
        path="/development/component/ReactDayPickerTestComponent"
        component={ReactDayPickerTestComponent}
      />
      <TestRoute
        exact
        path="/development/component/Typography"
        component={Typographys}
      />
      <TestRoute exact path="/development/component/Input" component={Inputs} />
      <TestRoute exact path="/development/component/Toast" component={Toast} />
      <TestRoute
        exact
        path="/development/component/SelfAuthentication"
        component={AutenticationTest}
      />
      <TestRoute
        exact
        path="/development/component/componentCart"
        component={CartPage}
      />
      <TestRoute
        exact
        path="/development/component/Coupon"
        component={CouponTest}
      />
      <TestRoute
        exact
        path="/development/dev/virtualized"
        component={VirtualizedTestPage}
      />
      <TestRoute exact path="/laboratory" component={LaboratoryIndex} />
      <TestRoute
        exact
        path="/laboratory/shopbyBestReviewTest"
        component={ShopbyBestReviewTest}
      />
      <TestRoute
        exact
        path="/development/dev/NotionDB"
        component={NotionDBTestPage}
      />
      <TestRoute exact path="/development/dev/NotionTerms" />
      <TestRoute
        exact
        path="/development/dev/NotionDB"
        component={NotionDBTestPage}
      />
      <TestRoute exact path="/laboratory" component={LaboratoryIndex} />
      <TestRoute
        exact
        path="/laboratory/NotionTerms"
        component={NotionTermsTestPage}
      />
      <TestRoute
        exact
        path="/laboratory/NotionStoreCategory"
        component={NotionStoreCategoryTestPage}
      />
      <TestRoute
        exact
        path="/laboratory/NotionDailyStatistics"
        component={NotionDailyStatisticsTestPage}
      />
      <Route exact path="/site-exhibition/:code" component={SiteExhibition} />
      <Route component={Main} />
    </Switch>
  );
};

export default SwitchRoute;
