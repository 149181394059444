import React, { useEffect, useState } from 'react';
import { getDeviceId } from 'utils/getDeviceId';
import { createFeedbackLog, getTrackId } from 'api/feedbackLogs';
import {
  ITrackIdRequest,
  TAction,
  TSection,
  TTarget,
} from 'types/feedbackLogs';
import { version } from '../../package.json';

// TrackId를 발급받기 위한 피드백 로그 hook.
const useGetTrackId = () => {
  const [sessionTrack, setSessionTrack] = useState(() =>
    sessionStorage.getItem('track'),
  );

  useEffect(() => {
    (async () => {
      try {
        const userAgent = navigator.userAgent;
        if (!userAgent) return null;

        const isMobile = /Mobile/i.test(userAgent);
        let deviceId = null;
        let previousTrackId = null;

        if (sessionTrack) {
          const jsonTrack = JSON.parse(sessionTrack);

          if (jsonTrack.trackId) {
            previousTrackId = jsonTrack.trackId;
          }
        }

        if (!deviceId && isMobile) {
          deviceId = getDeviceId();
        }

        const body: ITrackIdRequest = {
          deviceId,
          deviceType: isMobile ? 'mobile' : 'pc',
          platform: isMobile ? getMobileOS() : 'web',
          os_version: userAgent,
          front_version: version,
          issuedTimestamp: new Date().getTime(),
          issuedPage: getPage(window.location.pathname),
          issuedPageUrl: window.location.href,
          previousTrackId,
        };

        const newTrackId = await getTrackId(body);
        if (newTrackId) {
          const trackBody = {
            trackId: newTrackId,
            trackTime: new Date(),
            launchTime: getLaunchTime(),
          };
          sessionStorage.setItem('track', JSON.stringify(trackBody));
        }
      } catch (error: any) {
        console.log('useGetTrackId ERROR : ', error);
      }
    })();
  }, []);
};

export default useGetTrackId;

/**
 * 현재 페이지의 유형, issuedPageUrl로는 유형 분리하기가 까다롭기에 적당한 페이지의 카테고리를 나누고 지정함.
 * 아래 링크의 쓰레드 참조할것.
 * {@link https://nexteditionworkspace.slack.com/archives/C06KM2981MG/p1719978751367129}
 *
 */
const getPage = (pathname: string) => {
  switch (true) {
    // 홈
    case /^\/$/.test(pathname):
      return 'home';

    // 캠핑장 검색탭
    case /^\/search$/.test(pathname):
      return 'campSearch';

    // 스토어 홈
    case /^\/product$/.test(pathname):
      return 'storeHome';

    // 캠핑생활 피드
    case /^\/feed$/.test(pathname):
      return 'feed';

    case /^\/feed\/\w+$/.test(pathname):
      return 'feedDetail';

    // 랜딩페이지
    case /^\/landing\/\w+$/.test(pathname):
      return 'landing';

    // 마이페이지
    case /^\/mypage$/.test(pathname):
      return 'myPage';

    default:
      return pathname;
  }
};

const getMobileOS = () => {
  const userAgent = navigator.userAgent;

  // Android
  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios';
  }

  return 'unknown';
};

export const getLaunchTime = () => {
  /** 앱 실행 시점, 해당 피드백이 앱 실행한지 얼마 이후에 발생한건지를 쉽게 확인하기 위함 */
  let launchTime = sessionStorage.getItem('launchTime');

  if (!launchTime) {
    launchTime = `${new Date()}`;
    sessionStorage.setItem('launchTime', launchTime);
  }

  return launchTime;
};

export const postFeedbackLog = async ({
  action,
  target,
  targetId,
  targetParam,
  visitedPages,
  section,
  sectionId,
  section_param,
  pageId,
}: {
  action: TAction;
  target: string;
  targetId: string | null;
  targetParam?: any;
  visitedPages: string[];
  section?: TSection;
  sectionId?: string;
  section_param?: any;
  pageId?: string;
}) => {
  const sessionTrack = sessionStorage.getItem('track');
  if (!sessionTrack) return;

  const jsonTrack = JSON.parse(sessionTrack);

  const prevPageIndex = visitedPages.length - 2;
  let refererUrl = '';

  if (prevPageIndex >= 0) {
    refererUrl = visitedPages[prevPageIndex];
  }

  await createFeedbackLog({
    trackId: jsonTrack.trackId,
    trackTime: jsonTrack.trackTime,
    launchTime: getLaunchTime(),
    timestamp: new Date().getTime(),
    action,
    // actionParam
    target,
    targetId,
    targetParam,
    page: getPage(window.location.pathname),
    pageUrl: window.location.href,
    referer: getPage(refererUrl),
    refererUrl,
    section,
    sectionId,
    section_param,
    pageId,
  });
};
